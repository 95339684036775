@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.headSpan3 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.headSpan3 span {
    background-color: #002244;
    color: white;
    padding: 5px;
    border-left: 5px solid red;
}

.aboutPages3 {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 50px 150px;
    justify-content: center;
    background-image: linear-gradient(rgba(255, 255, 255, 0.648), rgba(193, 234, 252, 0.502)), url(../assets/bgele3.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;

}


.firstSection3 {
    display: flex;
    gap: 20px;
}

.contentFirst3 {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    width: 500px;
    padding: 0 10px;
}

.contentFirst3 h1 {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #002244;
    font-size: 2.7rem;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: transparent;
    font-style: normal;
}

.contentFirst3 h1:hover{
    color: #002244;
}

.FirstImgsection3 {
    width: 500px;
    height: 700px;
    object-fit: cover;
}


.FirstImgsection3 img {
    object-fit: cover;
    width: 500px;
    height: 600px;
}

.secondSection3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0;
}

.secondImg3 {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 1000px;
    height: 500px;
    /* background-color: rebeccapurple; */
    overflow: hidden;
}

.secondImg3 img {
    width: 100%;
}

.ContentSecond3 {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.11rem;
    padding: 20px;
    font-style: normal;

}

.ContentSecond3 h4 {
    color: #002244;
}

.Aboutlist3 {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.AboutUl3 {
    display: flex;
    flex-direction: column;
    gap: 10px;
   
}

.aboutli3 {
    list-style: none;
    border-left:5px solid red !important ;
    border: 2px solid #002244;
    padding: 20px;
    background-color: #002244;
    color: aliceblue;
}
.aboutli3:hover {
    border: 2px solid #002244;
    font-weight: 500;
    background-color: transparent;
    color: #002244;
}

@media screen and (min-width: 270px) and (max-width: 1260px) {
    .aboutPages3{
        padding: 10px;
        align-items: center;
    }
    .FirstImgsection3 {
        height: auto;
        width: auto;
    }
    .FirstImgsection3 img {
        width: 90vw;
        height: auto;
    }
    .contentFirst3{
        width: 90vw;
    }
    .secondImg3{
        width: 90vw;
    }
    .Aboutlist3{
        width: 90vw;
        align-items: center;
        justify-content: center;
    }

    .AboutUl3{
        padding: 0;margin: 0;
    }
    .firstSection3{
        width: 90vw;
        flex-direction: column;
    }
    .secondSection3{
        padding: 0;
    }

    .contentFirst3 h1 {
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #002244;
        font-size: 1.5rem;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        color: transparent;
        font-style: normal;
    }
    
    .contentFirst3 h1:hover{
        color: #002244;
    }
}