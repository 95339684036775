@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.headSpan {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.headSpan span {
    background-color: #002244;
    color: white;
    padding: 5px;
    border-left: 5px solid red;
}

.aboutPages {
    display: flex;
    flex-direction: column;
    padding: 50px 150px;
    justify-content: center;
    background-image: linear-gradient(rgba(255, 255, 255, 0.21), rgba(193, 234, 252, 0.411)), url(../assets/bgelem2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;

}


.firstSection {
    display: flex;
    gap: 20px;
}

.contentFirst {
    width: 500px;
    padding: 0 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.11rem;
}


.FirstImgsection {
    width: 500px;
    height: 700px;
    object-fit: cover;
}


.FirstImgsection img {
    object-fit: cover;
    width: 500px;
    height: 600px;
}

.secondSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0;
}

.secondImg {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 1000px;
    height: 500px;
    /* background-color: rebeccapurple; */
    overflow: hidden;
}

.secondImg img {
    width: 100%;
}

.ContentSecond {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.11rem;
    padding: 20px;
    font-style: normal;

}

.ContentSecond h4 {
    color: #002244;
}

@media screen and (min-width: 270px) and (max-width: 1260px) {
    .aboutPages {
        height: auto;
        padding: 15px;

    }

    .contentFirst {
        text-align: center;
        width: 90vw;
        height: auto;
    }

    .firstSection {
        flex-direction: column-reverse;
        align-items: center;
    }


    .FirstImgsection {
        height: auto;
        width: auto;
    }

    .FirstImgsection img {
        width: 90vw;
    }

    .secondSection{
        padding: 0;
    }
    .secondImg{
        height: auto;
        padding: 20px 0;
        width: auto;
    }

    .secondImg img {
        width: 70vw;
    }
}