@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.headSpan4 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.headSpan4 span {
    background-color: #002244;
    color: white;
    padding: 5px;
    border-left: 5px solid red;
}

.aboutPages4 {
    display: flex;
    flex-direction: column;
    padding: 50px 150px;
    justify-content: center;
    background-image: linear-gradient(rgba(255, 255, 255, 0.648), rgba(193, 234, 252, 0.699)), url(../assets/bgele3.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;

}


.firstSection4 {
    display: flex;
    gap: 20px;
}

.contentFirst4 {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    width: 500px;
    padding: 0 10px;
}

.contentFirst4 p {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 1.3rem;
    font-style: normal;
}

.FirstImgsection4 {
    width: 500px;
    height: 700px;
    object-fit: cover;
}


.FirstImgsection4 img {
    object-fit: cover;
    width: 500px;
    height: 600px;
}

.spanclass {
    background-color: #0061c2;
    color: aliceblue;
    padding: 0 4px;
    font-weight: 500;
}




.whyYouChooseImgSection4 {
    width: 500px;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}


#triangle-topleft4 {
    /* z-index: -1; */
    position: absolute;
    top: 0;
    left: -1.8%;
    width: 0;
    height: 0;
    border-top: 200px solid #002244;
    border-right: 200px solid transparent;
}

#triangle-bottomright4 {
    /* z-index: -1; */
    position: absolute;
    bottom: 0;
    right: -1.8%;
    width: 0;
    height: 0;
    border-bottom: 200px solid #002244;
    border-left: 200px solid transparent;
}

.WhyChooseImg4 {
    z-index: 1;
    width: 450px;
    height: 00px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.WhyChooseImg4 img {
    border: 6px solid white;
    width: 100%;
}

@media screen and (min-width: 270px) and (max-width: 1260px) {
    .headSpan4 {
        padding: 30px;
    }

    .aboutPages4 {
        margin-top: 30px;
        padding: 10px;
        align-items: center;
    }

    .contentFirst4 {
        width: 90vw;
        height: auto;
        padding: 0;
    }

    .firstSection4 {
        flex-direction: column;
    }

    .WhyChooseImg4 {
        height: auto;
        width: auto;
    }

    .WhyChooseImg4 img {
        width: 90vw;
    }

    #triangle-topleft4 {
        display: none;
    }

    #triangle-bottomright4 {
        display: none;
    }

    .whyYouChooseImgSection4 {
        width: 90vw;
        height: auto;
    }
}