.ContactBanner {
    display: flex;
    background-image: url(../assets/contact.jpg);
    background-position: center top -30px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 460px;
    align-items: center;
}

.ContactBanner h1 {
    position: absolute;
    color: #002244;
    right: 15%;
    top: 24%;
    font-size: 4rem;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.ContactBanner p {
    position: absolute;
    right: 17%;
    top: 42%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}


.contactImg img {
    width: 100%;
}

.contact {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: rgb(216, 219, 255);
    height: 700px;
}


.ContactInfo {
    width: 400px;
}

.ContactInfo span {
    padding: 8px;
    color: white;
    background-color: #002244;
    border-left: 7px solid red;
}

.ContactInfo h1 {
    font-size: 4rem;
    color: #002244;
}


.ContactInfo p {
    font-size: 1.2rem;
}

.callsection {
    display: flex;
    align-items: center;
    padding: 20px 0;
    gap: 20px;
    font-size: 1.2rem;
    font-weight: 500;
    color: rgb(0, 64, 159);
}

.iconCallSection {
    scale: 1.5;
}

.ContactFormsSecton {
    width: 700px;
}

.inputFileds {
    display: flex;
    gap: 10px;
}

.inputFileds input {
    padding-left: 30px;
    margin-top: 10px;
    width: 330px;
    height: 50px;
    border: none;
    background-color: rgb(255, 255, 255);
    font-size: 1rem;
    border-radius: 1rem;
}

.ContactFormsSecton textarea {
    outline: none;
    padding: 20px;
    margin-top: 10px;
    border-radius: 1rem;
    width: 650px;
    border: none;
    font-size: 1rem;
}

.ContactFormsSecton textarea:active {
    border: none;
}



/* CSS */
.button-30 {
    margin-top: 30px;
    align-items: center;
    appearance: none;
    background-color: #FCFCFD;
    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #36395A;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    width: 300px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
}

.button-30:focus {
    box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    transform: translateY(-2px);
    background-color: #002244;
    color: white;
}

.button-30:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
}

@media screen and (min-width: 270px) and (max-width: 800px) {

    .ContactBanner h1,
    p {
        display: none;
    }

    .contact {
        flex-direction: column;
        height: auto !important;
        align-items: center;
        justify-content: center;
        padding: 20px;
    }


    .ContactInfo {
        align-items: center;
        width: 90vw;
        height: auto;
    }


    .ContactFormsSecton {
        width: 90vw;
    }

    .inputFileds {
        flex-direction: column;
        width: 80vw;
    }

    .inputFileds input {
        width: 90vw;
    }

    .callsection {
        display: none;
    }

    .ContactFormsSecton textarea {
        padding: 20px;
        margin-top: 10px;
        border-radius: 1rem;
        width: 80vw;
        border: none;
        font-size: 1rem;
    }

    .button-30 {
        width: 90vw;
    }
}

@media screen and (min-width: 800px) and (max-width: 1200px) {

    .ContactBanner h1,
    p {
        display: none;
    }
    .callsection {
        display: none;
    }

    .contact {
        flex-direction: column;
        height: auto;
        padding: 80px;
        gap: 20px;
    }
}