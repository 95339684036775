* {
    font-family: "DM Sans", sans-serif;
}

.insurance1Service {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.489), rgba(0, 0, 0, 0.68)), url(../assets/unsplashService.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
}

.insuranceName1 {
    font-size: 6rem;
    color: azure;
}

.insurance1Bottom {
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

.insurance1Left {
    background-color: rgb(255, 255, 255);
    width: 30vw;
    height: 180vh;
}

.insurance1Right {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    /* height: 182vh; */
    height: auto;
}

.insuranceList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem 1.5rem 0.7rem 1.5rem;
    margin: 0.5rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.6rem;
    box-shadow: -3px -7px 10px -2px rgba(0, 0, 0, 0.19) inset;
    color: rgb(85, 26, 139);
}

.insuranceList:hover {
    background-color: rgb(85, 26, 139);
    color: white;
}

.insuranceListText {
    font-weight: 700;
}

.IconInsurance {
    height: 2rem;
    width: 2rem;
}

.InsuranceImage {
    margin: 50px 0 1rem 2rem;
    height: 22rem;
    width: 22rem;
    background-image: linear-gradient(rgba(0, 4, 6, 0.7), rgba(0, 0, 0, 0.7)), url(../assets/pexels1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 1rem;
}

.TalkToInsurance {
    position: sticky;
    top: 20px
}

.rightinsuranceImageSize {
    object-fit: cover;
    height: 70vh;
    width: 770px;
    border-radius: 1rem;
}

.rightinsuranceImageSize2 {
    object-fit: cover;
    height: 70vh;
    width: 770px;
    border-radius: 1rem;
}

.rightinsuranceText {
    padding: 50px;
    color: gray;
    text-align: justify;
    height: auto;
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
    .insurance1Service {
        height: 40vh;
        text-align: center;
    }

    .insuranceName1{
        text-align: center;
    }

    .InsuranceImage {
        margin: 0;
        height: 15rem;
        width: 15rem;
    }

    .rightinsuranceImageSize {
        height: auto;
        width: 60vw;
    }

    .rightinsuranceImageSize2 {
        height: auto;
        width: 60vw;
    }

    .rightinsuranceText {
        text-align: justify;
        height: auto;
        padding: 20px;
    }

    .insurance1Right {
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: rgb(255, 255, 255);
        height: auto;
    }

    .insurance1Bottom {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        height: auto;
    }

    .insurance1Left {
        background-color: rgb(255, 255, 255);
        width: 30vw;
        height: auto;
    }
}


@media screen and (min-width: 280px) and (max-width: 600px) {
    .insurance1Service {
        height: 40vh;
        width: 100vw;
    }

    .insuranceName1 {
        font-size: 40px;
        text-align: center;
    }

    .insurance1Left {
        display: none;
    }

    .rightinsuranceImageSize {
        height: auto;
        width: 83vw;
    }

    .rightinsuranceImageSize2 {
        height: auto;
        width: 83vw;
    }

    .rightinsuranceText {
        text-align: justify;
        height: auto;
        padding: 10px;
    }

    .insurance1Right {
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: rgb(255, 255, 255);
        height: auto;
    }

    .insurance1Bottom {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        height: auto;
    }


}

@media screen and (min-width: 200px) and (max-width: 280px) {
    .insurance1Service {
        height: 40vh;
        width: 100vw;
    }

    .insuranceName1 {
        font-size: 30px;
        text-align: center;
    }

    .insurance1Left {
        display: none;
    }
}